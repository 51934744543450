<ion-content class="date-ion-content">
  <div class="inner-page">
    <div class="mb-3">
      <div class="logo">
        <ion-img [src]="'/assets/images/logo.svg'"></ion-img>
      </div>
      <!-- Studennt Personal Information -->
      <div class="studennt-info" *ngIf="userDetails.userType=='STUDENT'">
        <div class="heading">
          <h4>Personal Information</h4>
          <ion-label class="caption m-0">Let your mates recognize you</ion-label>
        </div>
        <form name="personalForm" #personalForm="ngForm" autocomplete="off">
          <div class="input-content">
            <div class="login-input">
              <ion-input type="text" name="referralCode" id="referralCode" [ngModel]="userDetails.referralCode"
                (ngModelChange)="userDetails.referralCode=$event" placeholder="Referral Code"
                [disabled]="true"></ion-input>
            </div>
            <div class="login-input">
              <ion-input type="text" name="firstName" id="firstName" [ngModel]="userDetails.firstName"
                (ngModelChange)="userDetails.firstName=$event" placeholder="First Name" required></ion-input>
            </div>
            <div class="login-input">
              <ion-input type="text" name="lastName" id="lastName" placeholder="Last Name"
                [ngModel]="userDetails.lastName" (ngModelChange)="userDetails.lastName=$event" required></ion-input>
            </div>
            <ion-list class="checkbox-list gender-list">
              <ion-radio-group class="d-flex no-wrap" name="gender" [ngModel]="userDetails.gender" (ngModelChange)="userDetails.gender=$event"
                required>
                <ion-item>
                  <ion-label>Male</ion-label>
                  <ion-radio slot="start" mode="ios" value="male"></ion-radio>
                </ion-item>
                <ion-item>
                  <ion-label>Female</ion-label>
                  <ion-radio slot="start" mode="ios" value="female"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            <div class="login-input">
              <mat-form-field appearance="fill" class="example-form-field custom-datePicker"
                (click)="datepicker.open()">
                <input matInput [matDatepicker]="datepicker" placeholder="Date of Birth"
                  [(ngModel)]="userDetails.dateOfBirth" name="dateOfBirth">
                <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker>
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDatepickerApply (click)="submitDate()">Ok</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
            </div>

            <ion-list class="checkbox-list bord-list">
              <ion-label class="caption">I am a </ion-label>
              <ion-radio-group name="boardId" [ngModel]="userDetails.boardId"
                (ngModelChange)="userDetails.boardId=$event" (ionChange)="changeBoard($event)" #radioGroup required>
                <ion-item *ngFor="let board of boardOfEducation" [disabled]="board.status=='INACTIVE'">
                  <ion-label>{{board.displayName}}</ion-label>
                  <ion-radio slot="start" mode="ios" [value]="board.id">
                  </ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>

            <ion-list class="checkbox-list class-list" *ngIf="classOfEducations.length>0">
              <ion-label class="caption">{{(CurrentBoardName=='STUDENT')?'Class':'Experience'}}</ion-label>
              <ion-radio-group class="flex-wrap" id="ghgh" name="classId" [ngModel]="userDetails.classId"
                (ngModelChange)="userDetails.classId=$event" (ionChange)="changeClass($event)" required>
                <ion-item *ngFor="let class of classOfEducations">
                  <ion-label>{{class.displayName}}</ion-label>
                  <ion-radio slot="start" mode="ios" [value]="class.id"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>

          </div>
        </form>
        <div>
          <ion-button class="secondary-btn" [disabled]="!personalForm.valid" (click)="onClickProceed()">Proceed
          </ion-button>
        </div>
      </div>


      <!-- Parent Personal Information -->

      <div class="parent-info" *ngIf="userDetails.userType=='PARENT'">
        <form name="parentForm" #parentForm="ngForm" autocomplete="off">
          <div class="input-content">
            <!-- <ion-input type="text" name="referralCode" id="referralCode" [ngModel]="userDetails.referralCode"
              (ngModelChange)="userDetails.referralCode=$event" placeholder="Referral Code"
              [disabled]="true"></ion-input> -->
            <div class="login-input">
              <ion-input type="text" name="parentName" id="parentName" placeholder="Your name"
                [ngModel]="userDetails.parentName" (ngModelChange)="userDetails.parentName=$event" required>
              </ion-input>
            </div>
            <div class="heading">
              <h4>Childs Information</h4>
            </div>
            <div class="login-input">
              <ion-input type="text" name="firstName" id="firstName" [ngModel]="userDetails.firstName"
                (ngModelChange)="userDetails.firstName=$event" placeholder="First Name" required></ion-input>
            </div>
            <div class="login-input">
              <ion-input type="text" name="lastName" id="lastName" placeholder="Last Name"
                [ngModel]="userDetails.lastName" (ngModelChange)="userDetails.lastName=$event" required></ion-input>
            </div>
            <ion-list class="checkbox-list gender-list">
              <ion-radio-group name="gender" [ngModel]="userDetails.gender" (ngModelChange)="userDetails.gender=$event"
                required>
                <ion-item>
                  <ion-label>Male</ion-label>
                  <ion-radio slot="start" mode="ios" value="male"></ion-radio>
                </ion-item>
                <ion-item>
                  <ion-label>Female</ion-label>
                  <ion-radio slot="start" mode="ios" value="female"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            <div class="login-input">
              <mat-form-field appearance="fill" class="example-form-field custom-datePicker"
                (click)="datepicker.open()">
                <input matInput [matDatepicker]="datepicker" placeholder="Date of Birth"
                  [(ngModel)]="userDetails.dateOfBirth" name="dateOfBirth"  [max]="maxDate">
                <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker>
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDatepickerApply (click)="submitDate()">Ok</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
            </div>
            <ion-list class="checkbox-list bord-list">
              <ion-label class="caption">School Board</ion-label>
              <ion-radio-group name="boardId" [ngModel]="userDetails.boardId"
                (ngModelChange)="userDetails.boardId=$event" (ionChange)="changeBoard($event)" #radioGroup required>
                <ion-item *ngFor="let board of boardOfEducation"  [disabled]="board.status=='INACTIVE'">
                  <ion-label>{{board.displayName}}</ion-label>
                  <ion-radio slot="start" mode="ios" [value]="board.id">
                  </ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>

            <ion-list class="checkbox-list class-list" *ngIf="classOfEducations.length>0">
              <ion-label class="caption">Class you are studying now</ion-label>
              <ion-radio-group class="flex-wrap" id="ghgh" name="classId" [ngModel]="userDetails.classId"
                (ngModelChange)="userDetails.classId=$event" (ionChange)="changeClass($event)" required>
                <ion-item *ngFor="let class of classOfEducations">
                  <ion-label>{{class.displayName}}</ion-label>
                  <ion-radio slot="start" mode="ios" [value]="class.id"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
          </div>
        </form>
        <div>
          <ion-button class="secondary-btn" [disabled]="!parentForm.valid" (click)="onClickProceed()">Proceed
          </ion-button>
        </div>
      </div>


      <!-- Teacher Personal Information -->

      <div class="teacher-info" *ngIf="userDetails.userType=='TEACHER'">
        <div class="heading">
          <h4>Personal Information</h4>
          <ion-label class="caption m-0">Let your mates recognize you</ion-label>
        </div>
        <form name="teacherForm" #teacherForm="ngForm" autocomplete="off">
          <div class="input-content">
            <ion-input type="text" name="referralCode" id="referralCode" [ngModel]="userDetails.referralCode"
              (ngModelChange)="userDetails.referralCode=$event" placeholder="Referral Code"></ion-input>
            <div class="login-input">
              <ion-input type="text" name="firstName" id="firstName" placeholder="First Name"
                [ngModel]="userDetails.firstName" (ngModelChange)="userDetails.firstName=$event" required></ion-input>
            </div>
            <div class="login-input">
              <ion-input type="text" name="LastName" id="LastName" placeholder="Last Name"
                [ngModel]="userDetails.lastName" (ngModelChange)="userDetails.lastName=$event" required></ion-input>
            </div>
            <ion-list class="checkbox-list gender-list">
              <ion-radio-group name="gender" [ngModel]="userDetails.gender" (ngModelChange)="userDetails.gender=$event"
                required>
                <ion-item>
                  <ion-label>Male</ion-label>
                  <ion-radio slot="start" mode="ios" value="male"></ion-radio>
                </ion-item>
                <ion-item>
                  <ion-label>Female</ion-label>
                  <ion-radio slot="start" mode="ios" value="female"></ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            <div class="login-input">
              <mat-form-field appearance="fill" class="example-form-field custom-datePicker"
                (click)="datepicker.open()">
                <input matInput [matDatepicker]="datepicker" placeholder="Date of Birth"
                  [(ngModel)]="userDetails.dateOfBirth" name="dateOfBirth">
                <mat-datepicker-toggle [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker>
                  <mat-datepicker-actions>
                    <button mat-button matDatepickerCancel>Cancel</button>
                    <button mat-raised-button color="primary" matDatepickerApply (click)="submitDate()">Ok</button>
                  </mat-datepicker-actions>
                </mat-datepicker>
              </mat-form-field>
            </div>
            <ion-list class="checkbox-list bord-list">
              <ion-label class="caption">School Board</ion-label>
              <ion-radio-group name="boardId" [ngModel]="userDetails.boardId"
                (ngModelChange)="userDetails.boardId=$event" (ionChange)="changeBoard($event)" #radioGroup required>
                <ion-item *ngFor="let board of boardOfEducation" [disabled]="board.status=='INACTIVE'">
                  <ion-label>{{board.displayName}}</ion-label>
                  <ion-radio slot="start" mode="ios" [value]="board.id">
                  </ion-radio>
                </ion-item>
              </ion-radio-group>
            </ion-list>
            <!--Prefer  class-->
            <ion-list class="checkbox-list class-list" *ngIf="classOfEducations.length>0">
              <ion-label class="caption">Class you are studying now</ion-label>
              <ion-item [title]="class.displayName" ion-tooltip="{{class.displayName}}" *ngFor="let class of classOfEducations">
                <ion-label >{{class.displayName}}</ion-label>
                <ion-radio slot="start" mode="ios" [value]="class.id" (click)="onSelectedClasse(class)"></ion-radio>
              </ion-item>
            </ion-list>
            <!--Prefer  subjects-->
          </div>
        </form>
        <div>
          <ion-button class="secondary-btn" [disabled]="!teacherForm.valid" (click)="onClickProceed()">Proceed
          </ion-button>
        </div>
      </div>


    </div>
  </div>
</ion-content>
