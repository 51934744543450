import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import firebase from 'firebase/compat/app';
import { BehaviorSubject } from 'rxjs';
import { UserServiceService } from './user-service.service';
import { UserDetails } from '../model/user';
export class AuthInfo {
  constructor(public $uid: string) { }
  isLoggedIn() {
    return !!this.$uid;
  }
}
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  static UNKNOWN_USER = new AuthInfo({} as any);
  public authInfo$: BehaviorSubject<AuthInfo> = new BehaviorSubject<AuthInfo>(AuthService.UNKNOWN_USER);
  confirmationResult: firebase.auth.ConfirmationResult={} as firebase.auth.ConfirmationResult;
  constructor(private fireAuth: AngularFireAuth, public userService: UserServiceService) {

  }

  public signInWithPhoneNumber(recaptchaVerifier:any, phoneNumber:any) {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.signInWithPhoneNumber(phoneNumber, recaptchaVerifier)
        .then((confirmationResult) => {
          this.confirmationResult = confirmationResult;
          resolve(confirmationResult);
        }).catch((error) => {
          console.log(error);
          reject('SMS not sent');
        });
    });
  }

  public signInWithEmailPassword(email:any, password:any) {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.createUserWithEmailAndPassword(email, password).then((confirmationResult) => {
        resolve(confirmationResult);
      }).catch((error) => {
        console.log(error);
        reject('SMS not sent');
      });
    });
  }

  public async enterVerificationCode(code:any) {
    return new Promise<any>((resolve, reject) => {
      this.confirmationResult.confirm(code).then(async (result) => {
        const user = result.user;
        resolve(user);
      }).catch((error) => {
        reject(error.message);
      });

    });
  }

  public login(email: string, password: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.signInWithEmailAndPassword(email, password)
        .then(res => {
          if (res.user) {
            this.authInfo$.next(new AuthInfo(res.user.uid));
            resolve(res.user);
          }
        })
        .catch(err => {
          this.authInfo$.next(AuthService.UNKNOWN_USER);
          reject(`login failed ${err}`);
        });
    });
  }

  public resetPassword(email: string): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      this.fireAuth.sendPasswordResetEmail(email)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(`reset failed ${err}`);
        });
    });
  }

  public checkAuth() {
    return new Promise((resolve, reject) => {
      this.fireAuth.onAuthStateChanged(user => {
        if (user) {
          resolve(user);
        } else {
          this.userService.setUserDetails({} as any);
          this.logout();
          //localStorage.clear();
          this.userService.setUserDetails({} as any);
          resolve(false);
        }
      }).catch(err => {
        reject(`reset failed ${err}`);
      });
    });
  }
  public logout(): Promise<void> {
    try{

      this.authInfo$.next(AuthService.UNKNOWN_USER);
      this.userService.setUserDetails({} as UserDetails);
    }
    catch(e:any)
    {
      console.log(e);

    }
    return this.fireAuth.signOut();
  }
}
